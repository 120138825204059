import React from 'react';

function HelpPage() {
  return (
    <div className="help-page">
      <div className='container mx-auto my-8 p-4 sm:p-6 bg-white shadow-xl rounded-xl transition-all duration-500 ease-in-out'>
      <h4 className="text-center text-xl sm:text-2xl font-semibold mb-3 sm:mb-4 text-gray-800">Need Help?</h4>
      <p className="text-center text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
        Reach out to <a href="https://twitter.com/fitehal" target='_BLANK' rel="noreferrer">@fitehal</a> on twitter or email us at hello@peenak.com  
      </p>
    </div>
      
    </div>
  );
}

export default HelpPage;
