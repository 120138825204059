import React from 'react';

function MainContent() {
  return (
    <div className='container mx-auto my-8 p-4 sm:p-6 bg-white shadow-xl rounded-xl transition-all duration-500 ease-in-out'>
      <h4 className="text-center text-xl sm:text-2xl font-semibold mb-3 sm:mb-4 text-gray-800">Welcome Aboard</h4>
      <p className="text-center text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">Welcome to the WordCamp Udaipur 2023 Front Face Search! Upload your photo and let our advanced AI find you in the crowd at WordCamp Udaipur. Discover your event memories with ease!</p>
    </div>
  );
}

export default MainContent;
